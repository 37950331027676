import {Controller} from '@hotwired/stimulus';
import {useIntersection} from 'stimulus-use';
import device from '../js/device';
import {hasIntersectionObserver} from "../js/window-has";

export default class extends Controller {

    static values = {
        rendered: {type: Boolean, default: false},
        src: String,
        device: {type: String, default: 'mobile'},
    }

    connect() {
        if (hasIntersectionObserver()) {
            useIntersection(this, {
                rootMargin: "100%",
            })

            console.log('player init')

        } else {
            this.appear()
        }
    }

    appear() {
        if (this.renderedValue === true || (this.deviceValue !== 'auto' && device.getType() !== this.deviceValue)) {
            return;
        }

        // no render if YouTube player in page
        const youtubePlayer = document.getElementById("live-youtube");
        if (youtubePlayer) {
            return;
        }

        const script = document.createElement("script");

        script.setAttribute("type", "text/javascript");
        script.setAttribute("src", this.srcValue);

        this.element.appendChild(script);
        this.renderedValue = true;
    }
}