import {Controller} from '@hotwired/stimulus';
import throttle from 'lodash/throttle';
import {useIntersection} from 'stimulus-use'
import device from "../js/device";

export default class extends Controller {
    connect() {

        if (device.isDesktop()) {
            return;
        }


        this.oldScrollY = window.scrollY;
        useIntersection(this, {rootMargin: '200px'})


        this.scroll = throttle(this.scroll.bind(this), 100)
        window.addEventListener('scroll', this.scroll, {passive: true})
    }

    scroll() {

        if (window.scrollY < 1000){
            return;
        }

        if (this.oldScrollY < window.scrollY) {
            this.element.classList.add('hide')
        }

        if (this.oldScrollY > window.scrollY + 20) {
            this.element.classList.remove('hide');
        }

        this.oldScrollY = window.scrollY;
    }

}
