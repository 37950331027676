import Swal from 'sweetalert2';
import {getUser, logOut} from "./identity";
import {Urn} from "./urn";


export default {

    loginNotification() {



        Swal.fire({
            title: 'Login/Registrazione',
            showCancelButton: false,
            showConfirmButton: true,
            showDenyButton: true,
            denyButtonText: 'No grazie',
            confirmButtonText: 'Vai al login/registrazione',
            html: 'Per inviare un nuovo commento o rispondere a un commento è necessario essere registrati <br> Per registrarsi o effettuare la login vai alla pagina di autenticazione.',
        }).then((result) => {
            if (result.isConfirmed) {
                window.location.href = "/login/callback?action=start";
            }
        })


    },
    showLoggedModal() {
        let user = window.localStorage.getItem("auth_user");
        if (!user) {
            return;
        }

        let userJson = JSON.parse(user)

        Swal.fire({
            title: 'Modifica profilo / Esci',
            showCancelButton: false,
            showConfirmButton: true,
            showDenyButton: true,
            denyButtonText: 'Disconnetti utente',
            confirmButtonText: 'Modifica il nickname',
            html: `Modifica il nickname. <br>
<input type="text" id="dialog-edit-username" class="p-4"  data-action="keyup->login#checkNickname"  value="${userJson.username}">
<small>Ammesse solo minuscole e numeri</small>`,
        }).then((result) => {
            if (result.isConfirmed) {
                const e = document.getElementById("dialog-edit-username");

                let user = window.localStorage.getItem("auth_user");
                if (!user) {
                    return;
                }

                let userJson = JSON.parse(user)
                let token = window.localStorage.getItem('access_token');
                let username = e.value;

                let urn = new Urn(userJson._id).id;

                let data = {
                    username: username,
                    token: token,
                    urn: urn,
                };

                fetch(`/api/user`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(data)
                })
                    .then(response => response.json())
                    .then(data => {
                        console.log('Success:', data);

                        if (data === 200) {
                            getUser();

                        } else {
                            alert('Impossibile modificare il profilo riprovare più tardi 1');
                        }

                    })
                    .catch((error) => {
                        console.error('Error:', error);
                        alert('Impossibile modificare il profilo riprovare più tardi 2');
                    });


            }else if (result.isDenied) {
                console.log('logout');
                logOut();
                // Refresh the page
                location.reload();
            }
        })


    },



    async toast(title, icon, timer= 3000) {

        const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: timer,
            timerProgressBar: true
        })

        await Toast.fire({
            icon: icon,
            title: title
        })
    },
    async toastSuccess(title) {
        await this.toast(title, 'success');
    },
    close() {
        Swal.close();
    }


}
