export class Urn {
    /**
     * Constructor.
     */
    constructor(idOrUrn, class_, owner, tenant, partition, domain = Urn.DOMAIN) {
        idOrUrn = idOrUrn.toString();
        const match = idOrUrn.match(Urn.REGEX);
        if (match) {
            domain = match[1];
            partition = '' !== match[2] ? match[2] : null;
            tenant = '' !== match[3] ? match[3] : null;
            owner = '' !== match[4] ? match[4] : null;
            class_ = match[5];
            idOrUrn = match[6];
        }
        if (!class_) {
            throw new Error('URN class must be defined');
        }
        this._domain = domain;
        this._partition = partition !== null && partition !== void 0 ? partition : null;
        this._tenant = tenant !== null && tenant !== void 0 ? tenant : null;
        this._owner = owner !== null && owner !== void 0 ? owner : null;
        this._class = class_;
        this._id = idOrUrn;
    }
    /**
     * Checks if this is a valid urn or not.
     */
    static isUrn(urn) {
        const match = urn.toString().match(Urn.REGEX);
        return !!match && match[1] === Urn.DOMAIN;
    }
    /**
     * Gets the id (without the urn part).
     */
    get id() {
        return this._id;
    }
    /**
     * The domain of the resource.
     */
    get domain() {
        var _a;
        return (_a = this._domain) !== null && _a !== void 0 ? _a : '';
    }
    /**
     * The partition of the resource.
     */
    get partition() {
        var _a;
        return (_a = this._partition) !== null && _a !== void 0 ? _a : '';
    }
    /**
     * The tenant of the resource.
     */
    get tenant() {
        var _a;
        return (_a = this._tenant) !== null && _a !== void 0 ? _a : '';
    }
    /**
     * The owner of the resource.
     */
    get owner() {
        var _a;
        return (_a = this._owner) !== null && _a !== void 0 ? _a : '';
    }
    /**
     * The class of this urn.
     */
    get class() {
        return this._class;
    }
    /**
     * Converts the urn object to string.
     */
    toString() {
        var _a, _b, _c, _d;
        return `urn:${(_a = this._domain) !== null && _a !== void 0 ? _a : Urn.DOMAIN}:${(_b = this._partition) !== null && _b !== void 0 ? _b : ''}:${(_c = this._tenant) !== null && _c !== void 0 ? _c : ''}:${(_d = this._owner) !== null && _d !== void 0 ? _d : ''}:${this._class}:${this._id}`;
    }
    toJSON() {
        return this.toString();
    }
}
Urn.DOMAIN = 'calcioinfinito';
Urn.REGEX = new RegExp(`urn:([a-zA-Z0-9-.]*):([a-z0-9-]*):([a-z0-9-]*):([a-z0-9-]*):([a-z0-9-/]+):([a-z0-9-/]+)$`);
