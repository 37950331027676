import {Controller} from '@hotwired/stimulus';
import {useIntersection} from 'stimulus-use'
import {hasIntersectionObserver} from '../../js/window-has';
import {backOff} from "../../js/backoff";
import {consola as l} from "consola/basic";

export default class extends Controller {

    static values = {
        url: String,
        rendering: {type: Boolean, default: false},
        page: Number,
        maxPage: Number,
        category: String,
        advSection: {type: String, default: 'homepage'},
    }

    async connect() {
        if (hasIntersectionObserver()) {
            useIntersection(this, {
                rootMargin: "100%",
            })
        }

        setTimeout(() => {
            if (this.isVisible() && this.renderingValue === false) {
                this.appear();
            }
        }, 1_000)

    }

    async appear() {

        // if in rendering old iteration
        if (this.renderingValue === true) {
            return;
        }

        this.renderingValue = true;

        let params = {};
        params.page = this.pageValue;
        params.advSection = this.advSectionValue;


        if (this.categoryValue) {
            params.category = this.categoryValue;
        }

        const urlParams = new URLSearchParams(params);

        const response = await fetch(`${this.urlValue}?${urlParams.toString()}`);

        // error
        if (!response?.ok) {
            this.renderingValue = false;
            return;
        }

        // no more content
        if (this.pageValue === this.maxPageValue || response.status === 204) {
            this.element.remove();
            return;
        }

        this.element.insertAdjacentHTML('beforebegin', await response.text())

        this.pageValue++;
        this.renderingValue = false;

        if (this.isVisible()) {
            await this.appear();
        }

    }
}