import {Controller} from '@hotwired/stimulus';
import {useIntersection} from 'stimulus-use'
import {hasIntersectionObserver} from "../js/window-has";

export default class extends Controller {

    static values = {
        isFirstItem: {type: Boolean, default: false},
        urlNext: String
    }

    connect() {
        if (hasIntersectionObserver()) {
            useIntersection(this, {
                rootMargin: "50%",
            })
        }


    }
    _appendPlaceholder() {

        const PLACEHOLDER = `<div class="match-container snap-start h-40 is-placeholder" data-controller="match"><div class="rounded-lg bg-background w-[80vw] h-38"></div></div>`;
        this.element.insertAdjacentHTML('afterend', PLACEHOLDER)
    }
    async appear() {


        if (this.element.nextElementSibling === null) {
            const response = await fetch(this.urlNextValue);

            this.element.insertAdjacentHTML('afterend', await response.text())
        }

        if (this.element.previousElementSibling === null && this.isFirstItemValue === false) {
            // @todo get prev match?
            //console.log('get-> prev')
        }

        /*
        *
        if (this.element.nextElementSibling.classList.contains('is-placeholder')){
            console.log('get-> is-placeholder')
        }
        */


    }
}