export default {
    localStorageKey: 'auth_user',
    localStorageAccessToken: 'auth_user',
    setUser(user) {
        /*
        *
        const item =  storage.getItem(this.localStorageKey);
        item.set(user);
         storage.save(item);
        */
    },
    deleteUser() {
        /*
        *
           storage.deleteItem(this.localStorageKey);
        */
    },
    isLogged() {
        return Boolean(this.getUser())
    },
    getUser() {

        let user = window.localStorage.getItem(this.localStorageKey);
        if (!user) {
            return false;
        }

        return JSON.parse(user)

    },
    getAccessToken() {
        let token = window.localStorage.getItem(this.localStorageAccessToken);
        if (!token) {
            return null;
        }

        return token
    }
};

