import {Controller} from '@hotwired/stimulus';
import {useIntersection} from 'stimulus-use';
import device from '../js/device';
import {hasIntersectionObserver} from "../js/window-has";

import {getToken, getUser, logOut, refreshToken, startAuth} from "../js/identity";
import user from "../js/user";
import modals from "../js/modals";

export default class extends Controller {

    static values = {
        authUrl: String
    }

    static targets = [ "menuIcon" ]


    refreshTokenTime = 60 * 30;

    connect() {

        /*
        * refresh token
        */
        let refreshTokenDiff = Math.floor((new Date().getTime() - parseInt(window.localStorage.getItem('refresh_token_timestamp') || 0)) / 1000);
        if (refreshTokenDiff > this.refreshTokenTime) {
            refreshToken()
        }


        if (user.isLogged()) {
            this.menuIconTarget.classList.remove('text-white')
            this.menuIconTarget.classList.add('text-cm')
        }

        /*
        *
        let user = window.localStorage.getItem("auth_user");
        if (user) {
            addUserLoginUi();
        } else {
            removeUserLoginUi()
        }
        */



    }

    async openModal() {

        if (user.isLogged()) {
            await modals.showLoggedModal();
        } else {
            await modals.loginNotification();
        }
    }

    checkNickname(e){

        console.log(e.currentTarget)

        let val = e.currentTarget.value
        let format = /[A-Z` !@#$%^&£§°ç°*()+=\[\]{};':"\\|,<>\/?~]/g;
        e.currentTarget.value = (val.replaceAll(format, ''))
    }


    addUserLoginUi() {

    }


    removeUserLoginUi() {

    }



}