import {Controller} from '@hotwired/stimulus';
import {useIntersection} from 'stimulus-use';
import device from "../js/device";

export default class extends Controller {

    rendered = false

    static values = {
        isDesktop: {type: Boolean, default: false},
        isMobile: {type: Boolean, default: false}
    }

    connect() {
        useIntersection(this, {
            rootMargin: "100%",
        })

    }

    appear() {

        if (device.getType() === 'desktop' && !this.isDesktopValue) {
            return false;
        }

        if (device.getType() === 'mobile' && !this.isMobileValue) {
            return false;
        }


        // Render Adv
        if (this.rendered === false) {
            this.rendered = true

            if (this.isMobileValue) {
                // mobile tag
                this.element.insertAdjacentHTML('beforeend', `<div data-openweb-ad data-row="1" data-column="1"></div>`)
            } else {
                // desktop tag
                this.element.insertAdjacentHTML('beforeend', `<div data-openweb-ad data-row="1" data-column="3"></div>`)
            }

        }
    }
}
