import {initializeApp} from "firebase/app";
import {getMessaging, getToken} from "firebase/messaging";
import Swal from "sweetalert2";


const PUBLIC_VAPID_KEY = 'BPlrYo9u3QOBaGq-UOdAwPvw6wqVYKNqJrFlZF8EMcU06gayRkcMEUaD8rLK7eCC2GHBEE8fHc5CBvP3cdyj-uw';
const SUBSCRIBE_TO_TOPIC_URL = '/api/notification/add-user-to-topic';
const UNSUBSCRIBE_TO_TOPIC_URL = '/api/notification/delete-user-to-topic';
const iconButton = document.getElementById("dialog-push-notification");
let messaging;

let configs = {
    subscribe_notification_selector: '.subscribe-notifications',
    unsubscribe_notification_selector: '#unsubscribe-notifications',
    not_subscribe_notifications_dialog: '#not-subscribe-notifications',
    firebase_notification_topic_key: 'firebase_noftification_topic',
    cookieShowPushNotificationDialog: 'show_push_dialog',
    last_engage_date_key: 'firebase_notification_engage_timestamp'
};

let _fireToast = async function (icon, title, timer = 3_000) {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: timer,
        timerProgressBar: true
    })

    await Toast.fire({
        icon: icon,
        title: title
    })
}


/**
 * subscribeToTopic via server
 * @see https://firebase.google.com/docs/cloud-messaging/js/send-multiple#subscribe_the_client_app_to_a_topic
 */
let subscribeToTopic = async function (topic, token) {
    console.log('Notification: Subscribe to ' + topic + ' and token ' + token);

    const response = await fetch(SUBSCRIBE_TO_TOPIC_URL, {
        method: "POST",
        body: JSON.stringify({
            token: token,
            topic: topic
        })
    });

    if (response?.ok) {
        showUnsubscribeNotificationButton();

        let data = await response.text()

        window.localStorage.setItem(configs.firebase_notification_topic_key, topic);
        console.log('Notification: subscribe token status:' + JSON.stringify(data));
        await _fireToast('success', 'Iscrizione avvenuta con successo');
        iconButton.setAttribute('data-subscribed', '1');
        iconButton.classList.add('cm-notification')
        iconButton.classList.remove('cm-refresh', 'animate-spin', 'flex')

    } else {

        let data = await response.text()
        console.log('Notification: subscribe token error status:' + JSON.stringify(data));
    }

    console.log('Notification: Subscribed to ' + topic);
};

let unSubscribe = function () {
    console.log('Notification: unSubscribe');

    getToken(messaging, {vapidKey: PUBLIC_VAPID_KEY}).then(async function (currentToken) {
        if (currentToken) {
            console.log('Notification: unSubscribe token get succesfully ' + currentToken);


            const response = await fetch(UNSUBSCRIBE_TO_TOPIC_URL, {
                method: "POST",
                body: JSON.stringify({
                        token: currentToken,
                        topic: 'ilbianconero'
                    }
                )
            });

            if (response?.ok) {
                let data = await response.text()
                showSubscribeNotificationButton();
                window.localStorage.removeItem(configs.firebase_notification_topic_key);
                console.log('Notification: unsubscribe token status:' + JSON.stringify(data));
                await _fireToast('success', 'Disiscrizione avvenuta con successo');
                iconButton.setAttribute('data-subscribed', '0');

                iconButton.classList.add('cm-notification')
                iconButton.classList.remove('cm-refresh', 'animate-spin', 'flex')

            } else {
                let data = await response.text()
                console.log('Notification: unsubscribe token error status:' + JSON.stringify(data));
            }


        } else {
            window.localStorage.removeItem(configs.firebase_notification_topic_key);
            console.log('Notification: unSubscribe No Instance ID token available. Request permission to generate one.');
        }
    }).catch(function (err) {
        console.log('Notification: unSubscribe An error occurred while retrieving token. ', err);
    });
};

let checkIfPushAreEnabledAndSubscribe = async function (team) {

    try {
        const token = await getToken(messaging, {vapidKey: PUBLIC_VAPID_KEY});
        if (token) {
            console.log('Notification: checkIfPushAreEnabledAndSubscribe token get successfully ' + token);
            await subscribeToTopic(team, token);
        } else {
            console.log('Notification: No Instance ID token available. Request permission to generate one.');
        }
    } catch (err) {
        console.log('Notification: Error ', err);

        setTimeout(() => {
            checkIfPushAreEnabledAndSubscribe(team)
        }, 1_000)

    }

};

let checkUserPreferencesAndSubscribe = async function () {
    console.log('Notification: checkUserPreferencesAndSubscribe start');
    await checkIfPushAreEnabledAndSubscribe('ilbianconero');
};

let requestBrowserPermission = async function () {
    console.log('Notification: Request browser permission');

    const browserPermission = await Notification.requestPermission();

    if (browserPermission === 'denied') {
        console.log('Notification: init request permission denied');
        showSubscribeNotificationButton();
        alert('Le notifiche di sistema sono disattivate per questo sito.')
        iconButton.classList.add('cm-notification')
        iconButton.classList.remove('cm-refresh', 'animate-spin', 'flex')
    }

    if (browserPermission === 'granted') {
        console.log('Notification: init granted');
        await checkUserPreferencesAndSubscribe();
    }

};

let showSubscribeNotificationButton = function () {
    console.log('Notification: showSubscribeNotificationButton');
    const e = document.getElementById("dialog-push-notification");
    e.classList.remove('text-cm');
    e.classList.add('text-white');
    iconButton.setAttribute('data-subscribed', '0');
};

let showUnsubscribeNotificationButton = function () {
    console.log('Notification: showUnsubscribeNotificationButton');
    const e = document.getElementById("dialog-push-notification");
    e.classList.add('text-cm');
    e.classList.remove('text-white');
    iconButton.setAttribute('data-subscribed', '1');
};


let init = async function () {
    console.log('Notification: init');

    /**
     * if messaging is not set
     */
    if (typeof messaging === 'undefined') {
        console.log('Notification: messaging object not set');
        return;
    }


    /**
     * if user click in subscribe/unsubscribe button
     */
    iconButton.addEventListener("click", async (event) => {

        iconButton.classList.add('cm-refresh', 'animate-spin', 'flex')
        iconButton.classList.remove('cm-notification')


        if (iconButton.dataset.subscribed === '1') {
            unSubscribe();
            console.log('Notification: init click dialog non subscribed');
        } else {
            console.log('Notification: init click yes notification');
            await requestBrowserPermission();
        }
    });


    /**
     * if user is already subscribed
     */
    if (window.localStorage.getItem(configs.firebase_notification_topic_key)) {
        console.log('Notification: user already subscribed');
        showUnsubscribeNotificationButton();
    }

};

if (navigator.cookieEnabled &&
    'serviceWorker' in navigator &&
    'PushManager' in window &&
    'Notification' in window &&
    'fetch' in window &&
    'PushManager' in self &&
    'Notification' in self
) {
    console.log('%c Notification: Load firebase library', 'background: #222; color: #bada55');
    // Initialize Firebase
    const firebaseConfig = {
        apiKey: "AIzaSyBayZDfigoRrTlYy82Vd1C8kxCZVTO4S60",
        authDomain: "ilbianconero-web-push.firebaseapp.com",
        databaseURL: "https://ilbianconero-web-push.firebaseio.com",
        projectId: "ilbianconero-web-push",
        storageBucket: "ilbianconero-web-push.appspot.com",
        messagingSenderId: "656236048595",
        appId: "1:656236048595:web:6fdf1d768e86793c"
    };

    const app = initializeApp(firebaseConfig);
    messaging = getMessaging(app);
    await init();
    window.dispatchEvent(new Event("firebase-loaded"));
} else {
    console.log('Notification: Firebase messaging not loaded');
    const e = document.getElementById("dialog-push-notification");
    e.classList.remove('text-white');
}
