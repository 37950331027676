import {Controller} from '@hotwired/stimulus';
import {useClickOutside, useIntersection} from 'stimulus-use';
import {hasIntersectionObserver} from '../../js/window-has';
import throttle from 'lodash/throttle';
import device from "../../js/device";

export default class extends Controller {


    menuRendered = false

    static targets = ['searchForm', 'menuToggle', 'hideMenuContainer'];


    connect() {
        useClickOutside(this, {element: this.searchFormTarget})

        // only for mobile device
        this.headerSpacer = document.getElementById("main-header-hidden");

        //if header spacer exist enable floating menu
        if (this.headerSpacer && hasIntersectionObserver() && device.isMobile()) {
            this.oldScrollY = window.scrollY;

            useIntersection(this, {element: this.headerSpacer, rootMargin: '1000px'})

            this.scroll = throttle(this.scroll.bind(this), 100)
            window.addEventListener('scroll', this.scroll, {passive: true})
        }


    }


    scroll() {


        // if spacer is visible, nothing happen
        if (this.isVisible()) {
            this.element.classList.remove('hide')
            return
        }

        if (this.oldScrollY < window.scrollY) {
            this.element.classList.add('hide')
        }

        if (this.oldScrollY > window.scrollY + 20) {
            this.element.classList.remove('hide');
        }

        this.oldScrollY = window.scrollY;
    }


    clickOutside(event) {
        this.element.classList.remove('show-search')
        //this.searchFormTarget.querySelector('input').blur() // remove focus to search bar
    }

    showSearchForm() {
        this.menuToggleTarget.checked = false; // close menu if is opened
        document.body.classList.remove('overflow-hidden'); // remove no scroll to body
        this.element.classList.add('show-search')
        // this.searchFormTarget.querySelector('input').focus() // add focus search bar
    }


    async toggleMenu() {

        console.log('toggle?')

        if (this.menuToggleTarget.checked === true) {
            this.hideMenuContainerTarget.removeAttribute('hidden');
        } else {
            this.hideMenuContainerTarget.setAttribute('hidden', 'hidden');
        }
    }

}
