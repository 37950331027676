import {Controller} from '@hotwired/stimulus';
import throttle from 'lodash/throttle';

export default class extends Controller {
    connect() {
        this.scroll = throttle(this.scroll.bind(this), 1000)
        window.addEventListener('scroll', this.scroll, {passive: true})
    }

    scroll() {
        if (window.scrollY > 7000) {
            this.element.classList.add('show')
        } else {
            this.element.classList.remove('show')
        }
    }
}
